<template>
    <div class="container my-4">
        <div class="rounded alert alert-dismissible alert-light" role="alert" v-if="errorMessage">
            <div class="d-flex align-items-center">
                <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                Error: {{ errorMessage }}
            </div>
            <button type="button" class="ml-auto close" @click="errorMessage=''">&times;</button>
        </div>
        <input ref="scannerInput"
               v-model="barcode"
               @change="onItemScanned"
               @focus="isScannerFocused = true"
               @blur="isScannerFocused = false"
               type="text"
               class="mb-3 ui-hidden-accessible form-control"/>
        <div class="card">
            <div class="card-header">
                <div v-if="!loading" class="d-flex align-items-center">
                    <div>
                        Order {{ order.big_commerce_order_id }}: {{ order.status ? order.status.name : '' }}
                    </div>
                    <div v-if="hasPermissions('modify:orders')" class="dropdown ml-auto">
                        <span data-toggle="dropdown" class="text-secondary" style="cursor: pointer;">
                            <i class="fas fa-1x fa-ellipsis-h"/>
                        </span>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#order-settings-modal"
                               data-toggle="modal"
                               class="dropdown-item">Settings</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex align-items-center justify-content-center" style="padding: 0">
                <Spinner v-if="loading || !order" size="60" line-fg-color="gray"/>
                <div v-else class="flex-fill">
                    <div class="row">
                        <div class="col scan-module d-flex align-items-center justify-content-center" style="cursor: pointer;" @click="focusScanner">
                            <div v-if="error" class="d-flex flex-column align-items-center justify-content-center">
                                <i class="fal fa-times"></i>
                                {{ error }}, click to refocus
                            </div>
                            <div v-else-if="totalCompleted === itemsInOrder  && !scanAdditionalItems"
                                 @click="scanAdditionalItems = true"
                                 class="d-flex flex-column align-items-center justify-content-center">
                                <i class="fal fa-check"></i>
                                <span class="mt-2">All items scanned, click to scan additional items</span>
                            </div>
                            <div v-else-if="isScannerFocused">
                                <Spinner size="75" line-fg-color="gray" style="margin-bottom: 10px"/>
                                <span>Waiting for UUID scan</span>
                            </div>
                            <div v-else class="d-flex flex-column align-items-center justify-content-center">
                                <i class="fal fa-eye-slash"></i>
                                <span class="mt-2">Focus lost, click to refocus</span>
                            </div>
                        </div>
                        <div class="col d-flex">
                            <ul class="list-group flex-fill">
                                <OrderFulfillmentItem v-for="product in orderProducts"
                                                      :product="product"
                                                      :key="product.bigCommerceId"/>
                                <ItemList v-if="extraItems.length"
                                               :extra="true"
                                               :items="extraItems"/>
                            </ul>
                        </div>
                    </div>

                    <hr class="m-0">

                    <div v-if="order.bc_data && order.bc_data.customer_message">
                        <div class="d-flex flex-column p-4">
                            <b class="mb-2">Customer Notes</b>
                            <span>{{ order.bc_data.customer_message }}</span>
                        </div>
                        <hr v-if="shipments.length" class="m-0">
                    </div>

                    <div v-if="shipments.length" class="d-flex flex-column">
                        <b class="ml-4 mt-4">Shipments</b>
                        <ul class="list-group mt-2 flex-fill p-0">
                            <OrderFulfillmentShipmentDetails v-for="shipment in shipments"
                                                             :shipment="shipment"
                                                             :key="shipment.id"
                                                             @error-message="errorMessage = $event"/>
                        </ul>
                    </div>

                    <hr v-if="(order.bc_data && order.bc_data.customer_message) || shipments.length" class="m-0">

                    <div class="d-flex flex-column m-4" v-if="items.length">
                        <b>New shipment</b>

                        <div class="ml-4 mt-4">
                            <span>Items</span>
                            <ul class="list-group">
                                <li class="list-group-item"
                                    v-for="(productDetails, productId) in groupedItems"
                                    :key='productId'>
                                    <div class="d-flex flex-column"
                                         :style="{'color': !productDetails.valid_group ? '#d9534f': 'inherit'}">
                                        <span>
                                            {{ productDetails.items.length }} x {{ productDetails.items[0].product_name }}
                                        </span>
                                        <small v-if="!productDetails.valid_group">
                                            This product must be shipped in groups of
                                            {{ productDetails.items[0].product_shipped_in_quantity }}.
                                        </small>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <ShipmentCarriers @get-carrier="changedCarrier($event)"
                                          @updateSelectedShipmentConfirmationTypeId="selectedShipmentConfirmationTypeId = $event"
                                          class="ml-4 mt-4"/>

                        <div v-if="carrier.provider === 'SE'" class="ml-4 mt-4">
                            <ShipmentPackages :getRatesDisabled="getRatesDisabled"
                                              :carrier="carrier"
                                              :shipmentCount="shipments.length"
                                              :gettingRates="gettingRates"
                                              :selected-shipment-confirmation-type-id="selectedShipmentConfirmationTypeId"
                                              @getting-rates="gettingRates = $event"
                                              @deleted-package="rates=[]"
                                              @get-rates-data="setRates"
                                              @shipping-data-changed="resetData"/>
                            <ShipmentRates :rates="rates"
                                           :shipEngineId="shipEngineId"
                                           :selectedRateId="selectedRateId"
                                           :customerPaidText="customerPaidText"
                                           @update-rate-id="selectedRateId = $event"
                                           :items="items"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex">
                <button v-if="items.length"
                        @click="createOrderShipment"
                        :disabled="loading || !readyToCreateShipment"
                        class="btn btn-sm btn-secondary ml-auto">
                    {{ orderFulfilled ? 'Complete order' : 'Create shipment' }}
                </button>
            </div>
        </div>
        <OrderSettingsModal v-if="order.status"
                            :order="order"
                            @error-message="errorMessage = $event"
                            @update-order="getAllOrderData" />
    </div>
</template>

<script>
import numeral from 'numeral';
import {
    getOrder,
    getOrderProducts,
    getOrderShipments
} from '../../services/OrderService';
import { getItem } from '../../services/ItemService';
import { createShipment } from '../../services/ShipmentService';
import ProgressBar from 'vue-simple-progress';
import Spinner from 'vue-simple-spinner';
import OrderFulfillmentItem from '../../components/orders/OrderFulfillmentItem.vue';
import ItemList from '../../components/orders/ItemList.vue';
import ShipmentPackages from '../../components/shipments/ShipmentPackages.vue';
import ShipmentCarriers from '../../components/shipments/ShipmentCarriers.vue';
import ShipmentRates from '../../components/shipments/ShipmentRates.vue';
import OrderFulfillmentShipmentDetails from '../../components/shipments/OrderFulfillmentShipmentDetails.vue';
import OrderSettingsModal from '../../components/modals/OrderSettingsModal.vue';
import { hasPermissions } from '../../helpers/permissionUtils'

export default {
    name: 'OrderFulfillment',
    components: {
        Spinner,
        ProgressBar,
        OrderFulfillmentItem,
        ItemList,
        ShipmentPackages,
        ShipmentCarriers,
        ShipmentRates,
        OrderFulfillmentShipmentDetails,
        OrderSettingsModal
    },
    data() {
        return {
            order: {},
            carrier: {},
            orderProducts: [],
            items: [],
            extraItems: [],
            shipments: [],
            rates: [],
            barcode: '',
            error: '',
            errorMessage: '',
            loading: false,
            gettingRates: false,
            shipEngineId: null,
            selectedRateId: null,
            isScannerFocused: false,
            scanAdditionalItems: false,
            selectedShipmentConfirmationTypeId: null
        };
    },
    computed: {
        totalCompleted() {
            return this.orderProducts.reduce((acc, { qtyFulfilled }) => acc + qtyFulfilled, 0);
        },
        itemsInOrder() {
            return this.orderProducts.reduce((acc, { qtyToShip }) => acc + qtyToShip, 0);
        },
        getRatesDisabled() {
            return (this.totalCompleted < 1 && !this.extraItems.length) || !this.allGroupedItemsValid
        },
        readyToCreateShipment() {
            return (
                this.items.length && this.carrier && (
                    this.carrier.provider === 'SE' ? this.shipEngineId && this.selectedRateId : true
                ) && this.allGroupedItemsValid
            )
        },
        orderFulfilled() {
            return (
                this.itemsInOrder === this.totalCompleted &&
                this.totalCompleted > 0 &&
                (this.shipments.length ||
                (this.shipEngineId &&
                this.selectedRateId &&
                this.carrier))
            );
        },
        progressBarColor() {
            if (!this.loading) return this.orderFulfilled ? '#5cb85c' : '#5bc0de';
            return '#eee';
        },
        customerPaidText() {
            const shipping = this.order.bc_data.shipping_addresses[0];
            if (shipping.shipping_method === 'None') return '';
            const shippingMethod = shipping.shipping_method;
            const shippingPaid = numeral(shipping.cost_ex_tax).format('0,0.00');
            return `Customer paid $${shippingPaid} for ${shippingMethod}`;
        },
        /**
         * Returns an object which groups items by their product
         *
         * Example:
         * {
         *     123: {
         *         items: [Item, Item, Item]
         *         valid_group: true          // Product.shipped_in_quantity = 3 so valid_group = true
         *     },
         *     ...
         * }
         */
        groupedItems() {
            let products = {};
            // Loop through each newly scanned item for this session
            this.items.forEach(item => {
                // If this item's product already exists
                if (products.hasOwnProperty(item.product)) {
                    let product = products[item.product]
                    // Add the item to its product's list of items
                    product.items.push(item)
                    // Check if the product's list of items is valid
                    // Ensure that users cannot partially ship products with a shipped_in_quantity > 1
                    product.valid_group = products[item.product].items.length % item.product_shipped_in_quantity === 0
                } else {
                    // If the item's product doesn't already exist, add it and check if the shipped_in_quantity === 1
                    products[item.product] = {
                        items: [item],
                        valid_group: item.product_shipped_in_quantity === 1
                    }
                }
            })
            return products;
        },
        allGroupedItemsValid() {
            return Object.keys(this.groupedItems).every(product_id => {
                return this.groupedItems[product_id].valid_group
            })
        }
    },
    async mounted() {
        await this.getAllOrderData();
        this.focusScanner();
    },
    methods: {
        async getAllOrderData() {
            try {
                this.loading = true;
                await this.getOrder();
                await this.getOrderShipments();
                await this.getOrderProducts();
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        async getOrder() {
            const response = await getOrder(this.$route.params.id);
            this.order = response.data;
            if (![3, 11].includes(this.order.status.id)) {
                this.$router.push({ name: 'shipments-list', params: { bc_order_id: this.order.big_commerce_order_id } });
            }
        },
        async getOrderProducts() {
            this.orderProducts = await getOrderProducts(this.order, this.shipments);
        },
        async getOrderShipments() {
            const response = await getOrderShipments(this.order.id);
            this.shipments = response.data.filter(shipment => {
                return (
                    shipment.carrier.provider !== 'SE' ||
                    (shipment.carrier.provider === 'SE' && shipment.rate_id)
                );
            });
            this.shipments.forEach(shipment => {
                this.extraItems = this.extraItems.concat(shipment.items.filter(item => item.shipped_as_extra))
            });
        },
        async onItemScanned() {
            try {
                const uuid = this.barcode.toLowerCase();
                this.barcode = '';

                const response = await getItem(uuid);
                const item = response.data;

                if (!item.in_stock) return (this.error = 'Item is out of stock');

                const orderProduct = this.orderProducts.find(p => p.id === item.product);
                const orderProductFulfilled = orderProduct
                    ? orderProduct.qtyFulfilled >= orderProduct.qtyToShip
                    : false;
                item.in_stock = false;
                item.shipped_as_extra = !orderProduct || orderProductFulfilled;

                if (orderProduct) {
                    orderProduct.qtyInStock--;
                    if (orderProduct.qtyFulfilled < orderProduct.qtyToShip) {
                        orderProduct.qtyFulfilled++;
                    } else {
                        this.extraItems.push(item);
                    }
                } else {
                    this.extraItems.push(item);
                }
                this.items.push(item);
                this.error = '';
            } catch (error) {
                this.error = 'Item not found in inventory';
            }
        },
        async createOrderShipment() {
            try {
                this.loading = true;
                await createShipment(
                    this.carrier.provider === 'SE' ? this.shipEngineId : null,
                    this.carrier.provider === 'SE' ? this.selectedRateId : null,
                    this.carrier.id,
                    this.$route.params.id,
                    this.totalCompleted === this.itemsInOrder ? 2 : 3,
                    this.items
                );
            } catch (error) {
                console.log(error);
            }
            this.items = [];
            this.extraItems = [];
            this.shipEngineId = '';
            this.selectedRateId = '';
            this.rates = [];
            await this.getAllOrderData();

        },
        setRates(data) {
            this.rates = data.rates;
            this.shipEngineId = data.shipment_id;
        },
        focusScanner() {
            this.error = '';
            this.barcode = '';
            if (this.$refs.scannerInput) this.$refs.scannerInput.focus();
        },
        changedCarrier(carrier) {
            this.carrier = carrier;
            if (carrier.provider !== 'SE') {
                this.shipEngineId = null;
                this.selectedRateId = null;
                this.rates = [];
            }
        },
        resetData() {
            this.rates = [];
            this.selectedRateId = null;
        },
        hasPermissions
    }
};
</script>

<style scoped>
.ui-hidden-accessible {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.scan-module {
    min-height: 200px
}

.fa-times {
    color: red;
    font-size: 75px;
}

.fa-check {
    color: green;
    font-size: 75px;
}

.fa-eye-slash {
    font-size: 75px;
}

.col >>> .list-group-item {
    border-radius: 0;
    border-right: 0;
}

.col >>> .list-group-item:first-child {
    border-top: 0;
}

.col >>> .list-group-item:last-child {
    border-bottom: 0;
}

.card-body {
    min-height: 200px;
}
</style>
