<template>
    <li class="list-group-item flex-fill p-0">
        <div class="d-flex flex-row" style="padding: 15px;">
            <div class="d-flex align-items-center justify-content-center mr-3">
                <img :src="product.image" class="product-image" alt="Product Image">
            </div>
            <div class="d-flex flex-grow-1 flex-column">
                <span class="mb-1">{{ product.name }}</span>
                <small v-if="!product.missing">In stock: {{ product.qtyInStock }}</small>
                <small v-else>
                    Product not in inventory.
                    <router-link :to="{name: 'product-add'}">Add now.</router-link>
                </small>
                <small v-if="product.qtyShippedIn > 1"
                       :style="{'color': this.incompleteItemGrouping ? '#d9534f': 'inherit'}">
                    Must be shipped in groups of {{ product.qtyShippedIn }}
                </small>
            </div>
            <div class="d-flex align-items-center justify-content-center ml-3">
                <span class="ml-auto text-nowrap">{{ product.qtyFulfilled }} / {{ product.qtyToShip }}</span>
            </div>
        </div>
        <ProgressBar :val="progressBarValue"
                     :bar-color="progressBarColor"
                     size="10"
                     class="progress-bar"
                     v-if="!product.missing"/>
    </li>
</template>

<script>
import ProgressBar from 'vue-simple-progress';

export default {
    name: 'OrderFulfillmentItem',
    components: {
        ProgressBar
    },
    props: {
        product: Object
    },
    computed: {
        progressBarValue() {
            return (this.product.qtyFulfilled / this.product.qtyToShip) * 100;
        },
        progressBarColor() {
            if (this.itemFulfilled) {
                return '#5cb85c'
            } else if (this.incompleteItemGrouping) {
                return '#d9534f'
            } else {
                return '#5bc0de'
            }
        },
        itemFulfilled() {
            return (
                this.product.qtyToShip === this.product.qtyFulfilled &&
                this.product.qtyFulfilled > 0
            );
        },
        incompleteItemGrouping() {
            return this.product.qtyFulfilled % this.product.qtyShippedIn !== 0
        }
    }
};
</script>

<style>
.product-image {
    width: 40px;
    max-height: 40px;
    border-radius: 5px;
}

.progress-card {
    box-shadow: 0px 0px 2px rgb(143, 142, 142);
    border-radius: 0.625rem !important;
}

.no-progress-bar {
    border-bottom-left-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important;
}

.progress-card .progress-bar,
.progress-card .vue-simple-progress,
.progress-card .vue-simple-progress-bar {
    border-bottom-left-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important;
}

.progress-card .vue-simple-progress-bar {
    border-bottom-left-radius: 0.625rem;
}
</style>
