<template>
    <div>
        <div class="modal fade" id="order-settings-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        Order Settings
                    </div>
                    <div class="modal-body d-flex flex-column">
                        <form @submit.prevent="updateOrder" id="update-order-form">
                            <label class="mb-2" for="order-status-select">Status</label>
                            <select class="form-control form-control-sm"
                                    :disabled="updatingOrder"
                                    id="order-status-select"
                                    v-model="new_order_status_id">
                                <option v-for="(status_string, status_id) in order.status_options"
                                        :value="status_id" :key="status_id">
                                    {{ status_string }}
                                </option>
                            </select>
                            <small>
                                Changes made to the order status will also be made in BigCommerce and may result in no
                                longer being able to preview the order in Inventory.
                            </small>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button form="update-order-form"
                                :disabled="updatingOrder"
                                class="btn btn-sm btn-primary"
                                type="submit">
                            <span v-if="updatingOrder"
                                  class="spinner-border spinner-border-sm mr-2"
                                  role="status"></span>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {updateOrder} from "../../services/OrderService";

    export default {
        name: 'OrderSettingsModal',
        props: {
            order: Object
        },
        data() {
            return {
                new_order_status_id: null,
                updatingOrder: false
            }
        },
        mounted() {
            this.new_order_status_id = this.order.status.id;
        },
        methods: {
            async updateOrder() {
                this.updatingOrder = true;
                try {
                    await updateOrder(this.order.id, this.new_order_status_id)
                    this.$emit('update-order');
                } catch (error) {
                    console.log(error.response);
                    this.$emit('error-message', 'Failed to update order status');
                } finally {
                    $('#order-settings-modal').modal('hide');
                    this.updatingOrder = false;
                }
            }
        }
    }
</script>
