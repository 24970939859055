<template>
    <div>
        <small>
            <b>Items</b>
        </small>
        <div v-for="item in items" :key="item.id" class="mt-2">
            <small>
                Product: {{ item.product_name }}
            </small>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ShipmentDetailsItems',
        props: {
            items: Array
        }
    };
</script>
