<template>
    <div>
        <small>
            <b>Packages</b>
        </small>
        <div v-for="(pkg, index) in packages" :key="index" class="mt-2">
            <div class="d-flex">
                <div class="d-flex">
                    <small>
                        Weight: {{ pkg.weight.value }} {{ pkg.weight.value > 1 ? 'lbs' : 'lb' }}
                        <span class="mx-2">|</span>
                        Length: {{ pkg.dimensions.length }} in
                        <span
                            class="mx-2"
                        >|</span>
                        Width: {{ pkg.dimensions.width }} in
                        <span class="mx-2">|</span>
                        Height: {{ pkg.dimensions.height }} in
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShipmentDetailsPackages',
    props: {
        packages: Array
    }
};
</script>
