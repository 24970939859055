<template>
    <li class="list-group-item py-0 border-left-0">
        <div class="row">
            <div class="col mb-2 mt-2">
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                        <span>
                            {{ formatDate(shipment.created_at) }}, via
                            <b>{{ shipment.carrier.name }}</b>
                        </span>
                        <small v-if="hasPackages">
                            Tracking #:
                            <a :href="shipment.tracking_url" target="_blank">
                                {{ shipment.tracking_number }}
                            </a>
                        </small>
                        <ShipmentLabelButtons v-if="shipment.label_url"
                                              :shipment="shipment"
                                              @error-message="$emit('error-message', $event)"/>
                    </div>
                    <div class="ml-auto" v-if="$route.name === 'order-fulfillment'">
                        <small data-toggle="collapse"
                               @click="animateShipmentDetails"
                               style="cursor: pointer; color: #007bff;">
                            More information
                        </small>
                    </div>
                </div>
            </div>
            <div class="col shipment-details" :id="shipmentDetailsElementID">
                <ul class="list-group flex-fill">
                    <li class="list-group-item" v-if="hasPackages">
                        <ShipmentDetailsPackages :id="`shipment-${shipment.id}`"
                                                 :packages="shipment.packages"/>
                    </li>
                    <li class="list-group-item" v-if="hasItems">
                        <ShipmentDetailsItems :id="`shipment-${shipment.id}`"
                                              :items="shipment.items"/>
                    </li>
                </ul>
            </div>
        </div>
    </li>
</template>

<script>
import moment from 'moment';
import ShipmentLabelButtons from '../../components/shipments/ShipmentLabelButtons.vue';
import ShipmentDetailsPackages from '../../components/shipments/ShipmentDetailsPackages.vue';
import ShipmentDetailsItems from '../../components/shipments/ShipmentDetailsItems.vue';

export default {
    name: 'OrderFulfillmentShipmentDetails',
    components: {
        ShipmentLabelButtons,
        ShipmentDetailsPackages,
        ShipmentDetailsItems
    },
    props: {
        shipment: Object
    },
    data() {
        return {
            showShipmentDetails: false,
            shipmentDetailsElementID: 'shipment-details-' + this.shipment.id
        }
    },
    computed: {
        hasPackages() {
            return this.shipment.carrier.provider === 'SE';
        },
        hasItems() {
            return !!this.shipment.items.length
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('ll');
        },
        animateShipmentDetails() {
            this.showShipmentDetails = !this.showShipmentDetails;

            const vue_this = this;
            if (!this.showShipmentDetails) {
                $("#" + this.shipmentDetailsElementID).animate({
                    opacity: 0,
                    width: 0,
                    height: 0,
                    'flex-grow': 0
                }, 200);
            } else {
                $("#" + this.shipmentDetailsElementID).animate({
                    opacity: 1,
                    width: 100,
                    'flex-grow': 1
                }, 200, function() {
                    $("#" + vue_this.shipmentDetailsElementID).animate({
                        height:  $("#" + vue_this.shipmentDetailsElementID).get(0).scrollHeight
                    }, 250)
                });
            }
        }
    }
};
</script>

<style scoped>
    .list-group-item {
        border-radius: 0;
        border-right: 0;
    }

    .list-group-item:first-child {
        border-top: 0;
    }

    .list-group-item:last-child {
        border-bottom: 0;
    }

    .shipment-details {
        opacity: 0;
        width: 0;
        height: 0;
        flex-grow: 0;
        overflow: hidden;
    }
</style>
